
window.onload = () => {
    console.log('page is loaded');
};

const logo = document.getElementById('ost-logo');
const navbar = document.getElementById('navbar');
const navlogo = document.getElementById('nav-logo');
const learnBtn = document.getElementById('learn');
const homeBtn2 = document.getElementById('home-btn-main');
const aboutNavBtn = document.getElementById('about-nav-btn');
const wwdNavBtn = document.getElementById('wwd-nav-btn');
const portfolioNavBtn = document.getElementById('portfolio-nav-btn');
const careersNavBtn = document.getElementById('careers-nav-btn');
const contactUsNavBtn = document.getElementById('contact-us-nav-btn');

const homeBtn = document.getElementById('home-btn').parentElement;
const aboutNavBtn2 = document.getElementById('about-nav-btn2').parentElement;
const wwdNavBtn2 = document.getElementById('wwd-nav-btn2').parentElement;
const portfolioNavBtn2 =
    document.getElementById('portfolio-nav-btn2').parentElement;
const careersNavBtn2 =
    document.getElementById('careers-nav-btn2').parentElement;
const contactUsNavBtn2 = document.getElementById(
    'contact-us-nav-btn2'
).parentElement;

const navMenuBtn = document.getElementById('nav-menu');
const navModal = document.getElementById('mobile-nav-modal');
const navModalLayer = document.getElementById('nav-modal-layer');

const aboutUsScrollHeight = document.getElementById('about-us').offsetTop - 1;
const wwdScrollHeight = document.getElementById('solutions').offsetTop - 1;
const portfolioScrollHeight = document.getElementById('portfolio').offsetTop - 1;
const careersScrollHeight = document.getElementById('careers').offsetTop - 1;
const contactUsScrollHeight = document.getElementById('contact-us').offsetTop - 1;

const isMobile = window.matchMedia('(max-width: 600px)').matches;
let isNavOpen = false;
let isLogoShowing = true;

// console.log(careersScrollHeight, contactUsScrollHeight);


function removeActiveClassFromNavLinks() {
    homeBtn2.classList.remove('active');
    homeBtn2.parentNode.classList.remove('active');
    aboutNavBtn.classList.remove('active');
    aboutNavBtn.parentNode.classList.remove('active');
    wwdNavBtn.classList.remove('active');
    wwdNavBtn.parentNode.classList.remove('active');
    portfolioNavBtn.classList.remove('active');
    portfolioNavBtn.parentNode.classList.remove('active');
    careersNavBtn.classList.remove('active');
    careersNavBtn.parentNode.classList.remove('active');
    contactUsNavBtn.classList.remove('active');
    contactUsNavBtn.parentNode.classList.remove('active');
}

navMenuBtn.addEventListener('click', (e) => {
    navModal.style.transform = 'translateX(0)';
    navModalLayer.style.transform = 'translateX(0)';
    navModalLayer.style.opacity = 1;
});

navModalLayer.addEventListener('click', (e) => {
    navModal.style.transform = 'translateX(-100%)';
    navModalLayer.style.transform = 'translateX(-100%)';
    navModalLayer.style.opacity = 0;
});

homeBtn2.addEventListener('click', (e) => {
    // removeActiveClassFromNavLinks2();
    // homeBtn.classList.add('active');
    // homeBtn.childNodes[0].click();
    // navModal.style.transform = 'translateX(-100%)';
    // navModalLayer.click();
});

homeBtn.addEventListener('click', (e) => {
    removeActiveClassFromNavLinks2();
    homeBtn.classList.add('active');
    homeBtn.childNodes[0].click();
    navModal.style.transform = 'translateX(-100%)';
    navModalLayer.click();
});

aboutNavBtn2.addEventListener('click', (e) => {
    removeActiveClassFromNavLinks2();
    aboutNavBtn2.classList.add('active');
    aboutNavBtn2.childNodes[0].click();
    navModal.style.transform = 'translateX(-100%)';
    navModalLayer.click();
});

wwdNavBtn2.addEventListener('click', (e) => {
    removeActiveClassFromNavLinks2();
    wwdNavBtn2.classList.add('active');
    wwdNavBtn2.childNodes[0].click();
    navModal.style.transform = 'translateX(-100%)';
    navModalLayer.click();

});

portfolioNavBtn2.addEventListener('click', (e) => {
    removeActiveClassFromNavLinks2();
    portfolioNavBtn2.classList.add('active');
    portfolioNavBtn2.childNodes[0].click();
    navModal.style.transform = 'translateX(-100%)';
    navModalLayer.click();
});

careersNavBtn2.addEventListener('click', (e) => {
    removeActiveClassFromNavLinks2();
    careersNavBtn2.classList.add('active');
    careersNavBtn2.childNodes[0].click();
    navModal.style.transform = 'translateX(-100%)';
    navModalLayer.click();
});

contactUsNavBtn2.addEventListener('click', (e) => {
    removeActiveClassFromNavLinks2();
    contactUsNavBtn2.classList.add('active');
    contactUsNavBtn2.childNodes[0].click();
    navModal.style.transform = 'translateX(-100%)';
    navModalLayer.click();
});

function removeActiveClassFromNavLinks2() {
    homeBtn.classList.remove('active');
    aboutNavBtn2.classList.remove('active');
    wwdNavBtn2.classList.remove('active');
    portfolioNavBtn2.classList.remove('active');
    careersNavBtn2.classList.remove('active');
    contactUsNavBtn2.classList.remove('active');
}

function changeNavActiveOnScroll() {
    if (isMobile) {
        removeActiveClassFromNavLinks2();
        if (window.scrollY < aboutUsScrollHeight) {
        } else if (
            window.scrollY >= aboutUsScrollHeight &&
            window.scrollY < wwdScrollHeight
        ) {
            
            aboutNavBtn2.classList.add('active');
        } else if (
            window.scrollY >= wwdScrollHeight &&
            window.scrollY < portfolioScrollHeight
        ) {
            
            wwdNavBtn2.classList.add('active');
        } else if (
            window.scrollY >= portfolioScrollHeight &&
            window.scrollY < careersScrollHeight
        ) {
            
            portfolioNavBtn2.classList.add('active');
        } else if (
            window.scrollY >= careersScrollHeight &&
            window.scrollY < contactUsScrollHeight
        ) {
            
            careersNavBtn2.classList.add('active');
        } else if (window.scrollY >= contactUsScrollHeight) {
            
            contactUsNavBtn2.classList.add('active');
        }
    } else {
        removeActiveClassFromNavLinks();
        // console.log('scroll about', aboutUsScrollHeight);
        // console.log('scroll solution', wwdScrollHeight);
        // console.log('window scroll', window.scrollY);
        if (window.scrollY < aboutUsScrollHeight) {
            homeBtn2.classList.add('active');
            homeBtn2.parentNode.classList.add('active');
        } else if (
            window.scrollY >= aboutUsScrollHeight &&
            window.scrollY < wwdScrollHeight
        ) {
            aboutNavBtn.classList.add('active');
            aboutNavBtn.parentNode.classList.add('active');
        } else if (
            window.scrollY >= wwdScrollHeight &&
            window.scrollY < portfolioScrollHeight
        ) {
            wwdNavBtn.classList.add('active');
            wwdNavBtn.parentNode.classList.add('active');
        } else if (
            window.scrollY >= portfolioScrollHeight &&
            window.scrollY < careersScrollHeight
        ) {
            console.log('scroll portfolio');
            portfolioNavBtn.classList.add('active');
            portfolioNavBtn.parentNode.classList.add('active');
        } else if (
            window.scrollY >= careersScrollHeight &&
            window.scrollY < contactUsScrollHeight
        ) {
            console.log('scroll careers');
            careersNavBtn.classList.add('active');
            careersNavBtn.parentNode.classList.add('active');
        } else if (window.scrollY >= contactUsScrollHeight) {
            
            contactUsNavBtn.classList.add('active');
            contactUsNavBtn.parentNode.classList.add('active');
        }
        // console.log('scrollY', window.scrollY);
        if (window.scrollY <= 100) {
            if (isLogoShowing) return;
            logo.style.animation = 'showLogo .5s ease-in-out forwards';
            navlogo.style.animation = 'hideNavbarLogo .5s ease-in-out forwards';
            navbar.style.animation = 'hideNavBg .5s ease-in-out forwards';
            isLogoShowing = true;
        } else {
            logo.style.animation = 'hideLogo .5s ease-in-out forwards';
            navlogo.style.animation = 'showNavbarLogo .5s ease-in-out forwards';
            navbar.style.animation = 'showNavBg .5s ease-in-out forwards';
            isLogoShowing = false;
        }
    }
}

if (!isMobile) {
    navlogo.style.opacity = 0;
    navbar.style.backgroundColor = 'transparent';
}

const onScrollStop = (callback) => {
    let isScrolling;
    window.addEventListener(
        'scroll',
        (e) => {
            clearTimeout(isScrolling);
            isScrolling = setTimeout(() => {
                callback();
            }, 150);
        },
        false
    );
};

onScrollStop(() => {
    // console.log('The user has stopped scrolling');
    changeNavActiveOnScroll();
});

const lightOff = document.getElementById('light-off');
const lightOn = document.getElementById('light-on');
const banners = document.querySelectorAll('.banner');

banners.forEach(banner => {
    banner.addEventListener('pointerover', (e) => {
        lightOff.style.opacity = 0;
        lightOn.style.opacity = 1;
    });
    
    banner.addEventListener('pointerleave', (e) => {
        lightOff.style.opacity = 1;
        lightOn.style.opacity = 0;
    });

});


